@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: sans-serif;

  --bg: hsl(0, 0%, 15%);
  --fg: hsl(0, 0%, 85%);
  background: url("https://hihiqy1.nl/Maurice/assets/noise.png"), linear-gradient(var(--bg), var(--bg));
  color: var(--fg);
}